import React, { FC, useEffect } from 'react';
import { Row, Col } from 'antd';
import { useDispatch, connect } from 'react-redux';
import { createSelector } from 'reselect';

import { useTitle } from 'Hooks';
import { getPartnerComparativeDetails, getPartnerDetails } from 'Actions/partner';
import { InnerPageLayout, useIntl } from 'Common';
import PartnerComparativeDetails from 'Entities/PartnerComparativeDetails';
import { externalLinkBuilder, GuestRoutePath } from 'Lib/helpers/routes';
import { Store } from 'Store';
import theme from 'Lib/theme';

import { Header, Info } from './components';
import s from './Partner.module.pcss';

const ALL_TIME_RANGE: [] = [];

interface PartnerStoreProps {
    details: Store['partner']['details'];
    comparativeDetails: PartnerComparativeDetails | null;
}
const Partner: FC<PartnerStoreProps> = ({ details, comparativeDetails }) => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const { currentLocale } = intl;
    useTitle(intl.getMessage('partner_page_title'));

    useEffect(() => {
        dispatch(getPartnerDetails());

        // TODO add range picker
        dispatch(getPartnerComparativeDetails(ALL_TIME_RANGE));
    }, []);

    if (!details || !comparativeDetails) {
        return null;
    }

    return (
        <InnerPageLayout header={<Header />} className={theme.content.with_actions}>
            <div className={s.info}>
                <Info details={details} comparativeDetails={comparativeDetails} />
            </div>

            <Row>
                <Col span={24} sm={12}>
                    <div className={s.links}>
                        <div className={s.group}>
                            <a
                                href={window.PARTNER_OFFER_URL}
                                className={s.link}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {intl.getMessage('eula')}
                            </a>
                            <div className={s.desc}>
                                {intl.getMessage('partners_eula_desc')}
                            </div>
                        </div>
                        <div className={s.group}>
                            <a
                                href={externalLinkBuilder(currentLocale, GuestRoutePath.Partner)}
                                className={s.link}
                            >
                                {intl.getMessage('partners_terms')}
                            </a>
                            <div className={s.desc}>
                                {intl.getMessage('partners_terms_desc', {
                                    a: 'linkOffer',
                                })}
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </InnerPageLayout>
    );
};

const selectPartnerDetails = (store: Store) => store.partner.details;
const selectPartnerComparativeDetails = (store: Store) => store.partner.comparativeDetails;

const selector = createSelector(
    [selectPartnerDetails, selectPartnerComparativeDetails],
    (details, comparativeDetails) => ({ details, comparativeDetails }),
);

const mapStoreToProps = (store: Store) => (
    { ...selector(store) }
);

export default connect(mapStoreToProps)(Partner);
